/*!
Theme Name: <CLIENT_NAME>-Koach
Author: Koach
Author URI: https://koachhub.com/
Description: Custom theme developed for <CLIENT_NAME> client
*/

@import 'icons/thirdeye-icons';
@import 'common-style.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.theme--thirdeye {
	* {
		font-family: 'Poppins', sans-serif !important;
		list-style-type: disc;
	}

	.header {
		&__logo {
		  max-width: 195px;

		  img {
			width: 100%;
		  }
		}
	}

	.featured-video__title p {
		font-size: 1vh;

		@include grid-media($xs) {
			font-size: 2vh;
		}

		@include grid-media($sm) {
			font-size: 2.3vh;
		}
	}

	.discoveryButton {
		border-radius: 10px;
		color: white;
		background: #ff0000;
		border: 1px solid transparent;
		padding: 10px;
		text-decoration: none;
		display: block;
		width: 300px;
		margin: auto;
		margin-top: 20px;
		cursor: pointer;
		transition: all 0.5s ease;
		text-align: center;

		&:hover {
			background: transparent;
			border: 1px solid #ff0000;
			color: #ff0000;
			transition: all 0.5s ease;
		}
	}

	.downloadButton {
		border-radius: 10px;
		color: white;
		background: #ff0000;
		border: 1px solid transparent;
		padding: 10px;
		text-decoration: none;
		display: block;
		width: 300px;
		margin: auto;
		margin-top: 20px;
		cursor: pointer;
		transition: all 0.5s ease;
		text-align: center;

		&:hover {
			background: transparent;
			border: 1px solid #ff0000;
			color: #ff0000;
			transition: all 0.5s ease;
		}
	}

	.homepage .middle-video-reel.banner-video:first-of-type {
		@include grid-media($xs) {
			max-height: 55vh;
		}

		@include grid-media($lg) {
			max-height: 50vh;
		}
	}

	.buyButton {
		border-radius: 10px;
		color: #ff0000;
		background: transparent;
		border: 1px solid #ff0000;
		padding: 10px;
		text-decoration: none;
		display: block;
		width: 300px;
		margin: auto;
		margin-top: 20px;
		cursor: pointer;
		transition: all 0.5s ease;
		text-align: center;

		&:hover {
			background: #ff0000;
			color: white;
			transition: all 0.5s ease;
		}
	}

    .loginscreen {
        background-position: center;
        background-size: cover;
		height: 100%;
		padding-bottom: 140px;

      &:before {
        background: rgb(0, 0, 0);
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
	  &:after {
		background: rgb(2,0,36);
		background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,1) 56%);
		bottom: 0;
        content: '';
		height: 140px;
		left: 0;
		position: absolute;
		width: 100%;
      }
	  &__logo{
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;

		@include grid-media($md) {
			flex-direction: row;
			text-align: left;
		}

		.logo-title {
			h1 {
				font-size: 2.5rem;
				font-weight: bold;
				
			}
		}
	  }
      &__form {
		max-width: unset;

		@include grid-media($xs) {
			margin: auto;
			max-width: 90%;
		}

        .icon--logo {
          margin-bottom: 3vw;
          width: 50%;
        }
        h4 {
          display: block;
        }	
        .form__form--fields {
          .form--field {
            label {
              font-size: 10px;

			  @include grid-media($md) {
				font-size: 14px;
			}
            }
            .form-input {
              background-color: $white;
              border-radius: 1vh;
              color: $black;
            }
  
            &.password-field {
              .form-input {
                background-size: 2.3vh !important;
              }
              input[type='password'] {
                background-image: url('../../images/svg-images/password-show.svg');
              }
  
              input[type='text'] {
                background-image: url('../../images/svg-images/password-hide.svg');
              }
  
              button {
                right: 1.5vh;
                top: 5.7vh;
              }

			  a {
				font-weight: 500;
				font-size: 10px;

				@include grid-media($md) {
					font-size: 12px;
				}
			  }
            }
          }
          .form--buttons {
			align-items: center;
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-between;

			@include grid-media($xl) {
				flex-direction: row;
			}

            .btn {
              border-radius: 1vh;
              color: $black;
              font-weight: $font-medium;
			  height: 25px;
			  font-size: 10px;

			  @include grid-media($md) {
				height: 40px;
				font-size: 16px;
			}
  
              &:first-of-type {
                background-color: $red-thirdeye !important;
                color: $white !important;
              }
  
              &:last-of-type {
                background-color: $white !important;
              }
  
              &.btn--loader {
                background-color: transparent !important;
                border-radius: 100%;
                border-color: $red-thirdeye !important;
                border-bottom-color: $white !important;
				color: transparent !important;
                position: relative;
                top: 1px;
				width: 40px;
              }
            }
          }
        }
      }
	  &__container-wrapper {
		display: flex;
		flex-direction: column;
		max-width: 90%;
	  }
	  &__section-one {
		border-bottom: 1px solid #707070;
		display: flex;
		flex-direction: column;
		padding-bottom: 30px;

		@include grid-media($lg) {
			flex-direction: row;
			padding-bottom: 0;
		}

		&--left {
			@include grid-media($lg) {
				border-right: 1px solid #707070;
				padding-right: 120px;
			}

			h3 {
				&:first-of-type {
					color: $red;
					font-size: 20px;
					font-weight: 600;
				}

				&:nth-of-type(2) {
					font-size: 19px;
				}
			}

			p {
				font-size: 16px;

				&:last-of-type {
					margin-bottom: 0;
				}

				span {
					color:#ff0000;
				}
			}

			a {
				font-size: 18px;
				font-weight: 600;
				position: relative;
				text-decoration: underline;
			}
		}
		&--right {
			@include grid-media($md) {
				padding: 0 3% 0;
			}
		}
		&--buttons {
			display: flex;
			flex-direction: column;

			@include grid-media($md) {
				flex-direction: row;
			}
		}
	  }
	  &__section-two {
		margin: auto;
		margin-top: -100px;
		max-width: 90%;

		.tab-list {
			flex-wrap: wrap;
			height: 50px;
			display: flex;
			list-style: none;
			font-size: 18px;
			padding: 0;
			justify-content: space-evenly;
			margin: 0;
		}
		  
		.tabs {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			cursor: pointer;
			padding: 10px;
			font-size: 10px;

			@include grid-media($md) {
				font-size: 18px;
			}

			&:last-of-type {
				border: none;
			}
	
			&.active-tabs {
				&::before {
					border-radius: 2px;
					bottom: 0;
					content: "";
					display: block;
					position: absolute;
					left: 50%;
					width: calc(100% - 20px);
					height: 4px;
					transform: translate((-50%, -50%));
					background: $red;
				}
			}
		}
	
		.content-container {
			margin-top: 80px;

			@include grid-media($xxs) {
				margin-top: 20px;
			}

			@include grid-media($xs) {
				margin-top: 0;
			}

			@include grid-media($md) {
				margin-top: 40px;
			}

			// @include grid-media($lg) {
			// 	margin-top: 0;
			// }

			.content {
				display: none;
				padding: 10px;
				text-align: left;
	
				&.active-content {
					display: block;
				}

				p {
					font-size: 10px;

					@include grid-media($md) {
						font-size: 12px;
					}

					@include grid-media($lg) {
						font-size: 18px;
					}

					span {
						color: $red;
						font-weight: 600;

						a {
							color: unset;
							text-decoration: none;
						}
					}
				}

				.team {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-evenly;
					max-width: 80%;
					margin: auto;

					@include grid-media($md) {
						width: 90%;
					}
		
					@include grid-media($md) {
						width: 90%;
					}

					@include grid-media($xl) {
						width: 70%;
					}
				}
			}
		}
	  }
	  &__bradning {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 50px 0 50px;

		@include grid-media($xs) {
			flex-direction: row;
		}

		p {
			font-size: 15px;
		}
	  }
	  &__membership {
		&-wrapper {
			display: flex;
			flex-direction: column;
			margin: auto;
			margin-top: 40px;
			justify-content: space-between;
			align-items: center;

			@include grid-media($xs) {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-around;
			}

			@include grid-media($md) {
				max-width: 60%;
			}

			@include grid-media($lg) {
				max-width: 80%;
			}

			@include grid-media($xl) {
				max-width: 50%;
			}
		}

		a {
			text-decoration: none;
		}

		&-item {
			background-color: #4a4a4a;
			border-radius: 17px;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			width: 100%;
			height: 160px;
			margin-bottom: 30px;

			@include grid-media($xxs) {
				width: 255px;
			}

			p {
				font-size: 14px !important;
				margin:0;
				text-decoration: none;
			}

			h2 {
				font-size: 54px !important;
				margin: 0;
				text-decoration: none;

				span {
					font-size: 18px;
				}
			}
		}
	  }
    }

	.eye--logo {
		margin-bottom: 30px;
		max-width: 20rem;

		@include grid-media($md) {
			max-width: 30rem;
			margin-right: 100px;
		}
	  }

	.koach--logo {
	width: 210px;
	height: 70px;
	}

	.member {
	&-wrapper {
		width: 100%;
		margin: 10px;
		
		@include grid-media($xxs) {
			width: 30%;
		}

		@include grid-media($xs) {
			width: 30%;
		}

		@include grid-media($md) {
			width: 20%;
		}
		}
	&-profile {
		width: 100%;
		}
	}

	.contact--button {
		background: red;
		width: 300px;
		border-radius: 20px;
		margin: auto;
		margin-top: 30px;
		cursor: pointer;

		a {
			padding: 15px;
			display: block;
			color: white;
			text-decoration: none;
		}
	}

	.social--wrapper {
		background-color: $black;
		border-radius: 50px;
		display: flex;
		margin-top: 20px;

		@include grid-media($xl) {
			margin-top: 0;
		}

		a {
			position: relative;
		}

		.igIcon,
		.twitterIcon {
			height: 15px;
			margin: 15px 15px 10px 15px;
			width: 15px;

			@include grid-media($xl) {
				height: 30px;
				margin: 15px 15px 10px 15px;
				width: 30px;
			}
		}
	}

	.footer {
		&__wrapper {
			justify-content: space-between;
		}
		&__social-menu {
			display: none;
		}
	}
  }